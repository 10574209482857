import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fetchTimeout from "fetch-timeout";
import { fetchTimeout as fetchTest } from "../utils/mockup";
import { toast } from "react-toastify";

import { getWifiUrl, portalSimulator, castSimulator } from "../actions/config";
import usePortalData from "./usePortalData";
import { authMarketing, updatePortalSession, getPortalSession } from "../actions/portal";
import { useSignInWifiMutation } from "graphql/useWifi";

const useWifiService = () => {
    const dispatch = useDispatch();
    const fetch = portalSimulator() ? fetchTest : fetchTimeout;

    const [loading, setLoading] = useState(false);
    const { sessionKey, chainRef, projectRef, token, error } = usePortalData();
    const { signInWifi: executeSignIn } = useSignInWifiMutation();
    const [lastCallError, setLastCallError] = useState(null);

    const session = useSelector(getPortalSession);

    //TODO mostrar mensaje (traducido) segun error.code | buscar tabla de equivalencias
    // 500 - internal server error
    // 403 - limite de dispositivos por usuario alcanzado
    // 401 - Authentication failed (login fail)

    const request = (method, url, json, parseResponse, parseError) => {
        fetch(
            getWifiUrl() + "/v0/" + url,
            {
                method: method,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                },
                body: json ? JSON.stringify(json) : null,
            },
            20000
        )
            .then((http) => {
                if (!http.ok || http.status == 204) {
                    const err = new Error(http.statusText);
                    err.code = "WFS" + http.status;
                    err.stack = `[${method}] ${url}\n\n${json ? JSON.stringify(json) : null}\n\n${token}`;
                    throw err;
                }
                return http.json();
            })
            .then((body) => {
                return parseResponse ? parseResponse(body) : body;
            })
            .catch((err) => {
                if (!(err instanceof Error) && typeof err === "object") {
                    err = new Error(JSON.stringify(err));
                }
                if (typeof err === "string") {
                    err = new Error(err);
                }
                toast.error(err.message);
                err.stack = `[${method}] ${url}\n\n${json ? JSON.stringify(json) : null}\n\n${token}\n\n${err.stack}`;
                setLastCallError(err);
                if (parseError) {
                    parseError(err);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const signIn = async ({ type, email, google, room, surname, code, marketingAccepted }) => {
        setLoading(true);

        let post = {
            LoginType: type,
            SessionKey: sessionKey,
            MarketingAccepted: marketingAccepted,
        };

        switch (type) {
            case "pms":
                post.Room = room;
                post.Surname = surname;
                post.Email = email;
                break;
            case "code":
                post.Code = code;
                post.Email = email;
                break;
            case "email":
                post.RrssToken = google;
                post.Email = email;
                break;
            case "open":
                break;
            default:
                setLoading(false);
                toast.error("Unknown login type " + type);
                return;
        }

        dispatch(authMarketing(marketingAccepted));

        let response;
        try {
            response = await executeSignIn({
                variables: {
                    surname: post?.Surname || null,
                    rrssToken: post?.RrssToken || null,
                    email: post?.Email || null,
                    room: post?.Room || null,
                    code: post?.Code || null,
                    marketingAccepted: marketingAccepted || false,
                    chainRef: chainRef || null,
                    loginType: type || null,
                    sessionKey: sessionKey || null,
                    hotelRef: projectRef || null,
                },
            });

            if (response.errors) {
                console.error("GraphQL Errors:", response.errors);
                return;
            }

            if (response.data) {
                const loyaltyRef = response?.data?.signInWifi?.loyalty?.ref || null;
                const loyaltyEmail = response?.data?.signInWifi?.loyalty?.email || null;
                const verify = response?.data?.signInWifi?.email?.verify || null;
                const rooms = response?.data?.signInWifi?.room?.rooms || null;
                const googleAccessToken = response?.data?.signInWifi?.rrss?.googleAccessToken || null;
                const redirect = response?.data?.signInWifi?.redirect || null;
                const mikrotik = response?.data?.signInWifi?.login || null;

                dispatch(
                    updatePortalSession({
                        email: loyaltyEmail || email,
                        signup: false,
                        ref: loyaltyRef,
                        google: googleAccessToken,
                        rooms,
                        verify,
                        hash: null,
                        verified: null,
                        mikrotik,
                        redirect,
                    })
                );
            }
        } catch (error) {
            console.error("Network or Unexpected Error:", error.message);
            // toast.error("A network error occurred, please try again.");
            throw error;
        } finally {
            setLoading(false);
        }

        return response;
    };

    const updateCommercialConsent = (accepted = false) => {
        setLoading(true);
        let post = { MarketingAccepted: accepted, Ref: session.ref };
        return new Promise((resolve, reject) => {
            request(
                "PUT",
                "loyalty/" + session.ref + "/" + sessionKey,
                post,
                (response) => {
                    const success = response && response.ref == session.ref;
                    dispatch(updatePortalSession({ marketing: success }));
                    setLoading(false);
                    if (!success) {
                        throw new Error();
                    } else {
                        resolve(response);
                    }
                },
                (e) => {
                    reject(e);
                }
            );
        });
    };

    const chromecastPairing = () => {
        const simulator = castSimulator();
        const pairingRoom = session.room;
        const pairingURL = pairingRoom
            ? simulator
                ? "/pairing/?tokenMobile=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9"
                : pairingRoom.ccUrl
            : null;
        const fetch = simulator ? fetchTest : fetchTimeout;

        return new Promise((resolve, reject) => {
            setLoading(true);

            const parseResponse = (response) => {
                const hasChromecast = response && response.success && response.ccInfo && response.ccInfo.length > 0;
                setLoading(false);
                if (hasChromecast) {
                    resolve(response);
                } else {
                    reject(response);
                }
            };
            const parseError = (e) => {
                reject(e);
            };

            fetch(pairingURL, null, 30000)
                .then((http) => {
                    if (!http.ok || http.status == 204) {
                        throw new Error("HTTP " + http.status + " - " + http.statusText);
                    }
                    return http.json();
                })
                .then((body) => {
                    setLoading(false);
                    return parseResponse ? parseResponse(body) : body;
                })
                .catch((err) => {
                    setLoading(false);
                    let msg = err.message || err || "Unknown error";
                    toast.error(msg);
                    if (parseError) parseError(err);
                });
        });
    };

    const updateUserRadius = () => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            let post = {
                Dst: session.redirect.pwa ? session.getPWAURL(projectRef) : session.redirect.url,
                Username: session.mikrotik.urlData.username,
                Password: session.mikrotik.urlData.password,
                SessionKey: sessionKey,
            };
            request(
                "PUT",
                "user/" + session.mikrotik.urlData.username,
                post,
                () => {
                    setLoading(false);
                    resolve();
                },
                (e) => {
                    reject(e);
                }
            );
        });
    };

    const sendEmailValidation = () => {
        const link = session.returnURL(chainRef, projectRef, sessionKey);
        return new Promise((resolve, reject) => {
            setLoading(true);
            let post = {
                SessionKey: sessionKey,
                Ref: session.ref,
                Link: link,
            };
            request(
                "POST",
                "loyalty/verify",
                post,
                (response) => {
                    const hash = response ? response.hash : null;
                    dispatch(updatePortalSession({ hash, verified: null }));
                    setLoading(false);
                    if (hash) resolve();
                    else reject();
                },
                null
            );
        });
    };

    const validateEmail = (code) => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            const hash = session.hash;
            const email = session.email;

            if (!hash) {
                setLoading(false);
                reject("empty hash");
                return;
            }

            let post = {
                SessionKey: sessionKey,
                Code: code,
                Email: email ? email.toLowerCase() : null,
                Hash: hash,
                Ref: session.ref,
            };
            request(
                "POST",
                "loyalty/verify",
                post,
                (response) => {
                    const success = response && response.response && response.response.valid;
                    const mikrotik = response ? response.login : null;
                    dispatch(updatePortalSession({ hash, verified: success ? code : null, mikrotik }));
                    setLoading(false);
                    if (success) resolve();
                    else reject("invalid code"); //TODO traducir ésto
                },
                null
            );
        });
    };

    const signUp = ({ email, name, surname, birth, country, marketingAccepted }) => {
        setLoading(true);
        let post = {
            MarketingAccepted: marketingAccepted,
            Email: email ? email.toLowerCase() : null,
            Name: name,
            Surname: surname,
            ...(birth ? { BirthDate: birth } : {}),
            ...(country ? { CountryRef: country.toUpperCase() } : {}),
        };
        return new Promise((resolve, reject) => {
            request(
                "POST",
                "loyalty/" + sessionKey,
                post,
                (response) => {
                    const success = response && response.ref;
                    if (success) {
                        const loyaltyRef = response ? response.ref : null;
                        const loyaltyEmail = response ? response.email : null;
                        dispatch(
                            updatePortalSession({
                                email: loyaltyEmail || email,
                                ref: loyaltyRef,
                                google: null,
                                signup: true,
                            })
                        );
                        resolve(response);
                    } else {
                        throw new Error(response);
                    }
                },
                (e) => {
                    reject(e);
                }
            );
        });
    };

    const getPortalTokenFromSession = (sessionKey) => {
        setLoading(true);
        return new Promise((resolve, reject) => {
            request(
                "GET",
                "portal/accesstypes/" + sessionKey,
                null,
                (response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        throw new Error("empty response");
                    }
                },
                (e) => {
                    reject(e);
                }
            );
        });
    };

    return {
        signIn,
        signUp,
        getPortalTokenFromSession,
        chromecastPairing,
        updateCommercialConsent,
        sendEmailValidation,
        updateUserRadius,
        validateEmail,
        loading,
        error: lastCallError || error,
        ignoreError: () => {
            setLastCallError(null);
        },
    };
};

export default useWifiService;
