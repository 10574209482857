import gql from "graphql-tag";

export const SIGN_IN_WIFI = {
    query: gql`
        mutation SignInWifi(
            $surname: String
            $rrssToken: String
            $email: String
            $countryRef: String
            $hotelRef: ID!
            $room: String
            $birth: String
            $hotspot: String
            $code: String
            $marketingAccepted: Boolean
            $chainRef: ID!
            $loginType: String
            $sessionKey: String
        ) {
            signInWifi(
                surname: $surname
                rrssToken: $rrssToken
                email: $email
                countryRef: $countryRef
                hotelRef: $hotelRef
                room: $room
                birth: $birth
                hotspot: $hotspot
                code: $code
                marketingAccepted: $marketingAccepted
                chainRef: $chainRef
                loginType: $loginType
                sessionKey: $sessionKey
            ) {
                email {
                    hash
                    verify
                }
                error {
                    code
                    msg
                    errString
                }
                login {
                    url
                    urlData {
                        dst
                        password
                        username
                    }
                }
                loyalty {
                    birthDate
                    countryRef
                    email
                    emailVerified
                    gender
                    id
                    languageRef
                    lastAccess
                    locked
                    marketingAccepted
                    name
                    phone
                    photo
                    ref
                    sessionValidDate
                    signupApp
                    signupDate
                    signupWifi
                    surname
                    termsAccepted
                }
                redirect {
                    url
                    pwa
                }
                room {
                    rooms {
                        cc
                        ccUrl
                        code
                        name
                        number
                    }
                }
                rrss {
                    googleAccessToken
                }
                success
            }
        }
    `,
};
